import { postOrder } from "@/services";

export const ordersForm = {
  namespaced: true,
  state: {
    kit1: {
      name: "kit1",
      cantidad: 0,
    },
    kit2: {
      name: "kit2",
      cantidad: 0,
    },
  },
  mutations: {
    kit1(state, payload) {
      state.kit1.cantidad = payload;
    },
    kit2(state, payload) {
      state.kit2.cantidad = payload;
    },
  },
  actions: {
    setKit1({ commit }, payload) {
      commit("kit1", payload);
    },
    setKit2({ commit }, payload) {
      commit("kit2", payload);
    },
    async sendPedido({ commit }, { kit1, kit2 }) {
      try {
        const res = await postOrder({
          kits1: kit1,
          kits2: kit2,
        });
        res.status === 201 && commit("kit1", 0);
        res.status === 201 && commit("kit2", 0);
        return true;
      } catch (error) {
        console.log("error :>> ", error.response);
        return error.response;
      }
    },
  },
  getters: {},
};
