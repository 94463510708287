<template>
  <v-app-bar app color="primary" dark>
    <div class="d-flex align-center">
      <v-sheet color="white" rounded elevation="3" class="pl-3">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          transition="scale-transition"
          height="40"
          :src="require('@/assets/logo.png')"
          backgroundColor="white"
          width="100"
        />
      </v-sheet>
    </div>

    <v-spacer></v-spacer>

    <v-btn to="/login" text @click="logout">
      <span class="mr-2">Log out</span>
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AppBar",
  methods: {
    ...mapActions("auth", ["logout"]),
  },
};
</script>
