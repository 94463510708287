import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.green.darken3,
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        bgContainer: "#cbe4cb",
      },
      dark: {
        primary: colors.green.lighten3,
        bgContainer: colors.indigo.base,
      },
    },
  },
});
