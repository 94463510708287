<template>
  <v-app :style="{ backgroundColor: $vuetify.theme.themes[theme].bgContainer }">
    <!-- <v-app> -->
    <app-bar app v-if="loggedIn" />
    <v-main class="main">
      <v-container fluid class="container">
        <v-row v-if="loggedIn">
          <v-col cols="3">
            <navigation-drawer />
          </v-col>
          <v-col cols="9">
            <router-view />
          </v-col>
        </v-row>
        <v-row v-else-if="loading">
          <v-col cols="12"> loading </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import appBar from "./componentsLayouts/appBar.vue";
import Footer from "./componentsLayouts/footer.vue";
import navigationDrawer from "./componentsLayouts/navigationDrawer.vue";
export default {
  name: "MainLayout",
  components: {
    appBar,
    Footer,
    navigationDrawer,
  },
  data: () => ({
    //
  }),
  mounted() {
    this.verifyLogin();
  },
  computed: {
    ...mapState("auth", ["loggedIn", "loading"]),
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    ...mapActions("auth", ["verifyLogin"]),
  },
};
</script>

<style scoped>
/* #app {
  background-color: #cbe4cb;
} */
.container {
  max-width: 1400px;
  height: 100%;
}
</style>
