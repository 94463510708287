import router from "@/router";
import { getUserAdmin, postLogin, refreshToken, verifyToken } from "@/services";

export const auth = {
  namespaced: true,
  state: {
    loggedIn: false,
    loading: false,
  },
  mutations: {
    setLoggedIn(state, payload) {
      state.loggedIn = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    async verifyLogin({ commit }) {
      try {
        const token = localStorage.getItem("accessToken");
        const response = await verifyToken(token);
        console.log("response :>> ", response);
        commit("setLoggedIn", true);
      } catch (error) {
        console.log("Error login", error.response);
        try {
          const refresh = localStorage.getItem("refreshToken");
          const response = await refreshToken(refresh);
          console.log("Error refresh", response);
          localStorage.setItem("accessToken", response.data.access);
          commit("setLoggedIn", true);
        } catch (error) {
          console.log("Error not login :>> ", error.response);
          commit("setLoggedIn", false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("isAdmin");
        }
      }
    },
    async authLogin({ commit }, payload) {
      const { username, password } = payload;
      // commit("setLoading", true);
      try {
        const response = await postLogin({
          username,
          password,
        });
        console.log("response :>> ", response.data);
        const { access, refresh } = response.data;
        localStorage.setItem("accessToken", access);
        localStorage.setItem("refreshToken", refresh);
        const { data: checkIsAdmin } = await getUserAdmin();
        const isAdmin = [...checkIsAdmin.results].find(
          (element) => element.is_staff === true
        );
        localStorage.setItem("isAdmin", !!isAdmin);
        commit("setLoggedIn", true);
        commit("setLoading", false);
        router.push("/usuario/form-kit");
      } catch (error) {
        commit("setLoading", false);
        console.log("Error :>> ", error.response);
        return {
          status: false,
          textResponse: error.response.data.detail,
        };
      }
    },
    async logout({ commit }) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("isAdmin");
      commit("setLoggedIn", false);
    },
  },
  getters: {},
};
