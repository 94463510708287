<template>
  <v-fade-transition mode="out-in">
    <MainLayout v-if="loggingIn" />
    <!-- Login page -->
    <LoginView v-else />
  </v-fade-transition>
</template>

<script>
import MainLayout from "./layout/MainLayout.vue";
import LoginView from "./views/Login/LoginView.vue";
export default {
  components: { MainLayout, LoginView },
  name: "App",
  metaInfo: {
    title: "App",
    titleTemplate: "Kit Office",
    htmlAttrs: { lang: "es" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },

  data: () => ({
    loggingIn: true,
    //
  }),
};
</script>
