<template>
  <v-container>
    <v-row justify="center" align-content="center">
      <v-col cols="6">
        <v-card>
          <v-form>
            <v-card-title primary-title class="justify-center">
              KitOffice: Inicio de Sesión
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="username"
                    label="Usuario"
                    outlined
                    autofocus
                    hide-details="auto"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="password"
                    label="Contraseña"
                    outlined
                    :type="'password'"
                    hide-details="auto"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="snackbar">
                <v-col>
                  <v-alert dense outlined type="error">
                    {{ text }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-col cols="12" lg="12" sm="6">
                <v-btn block color="primary" height="50" @click="login">
                  Iniciar sesión
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-form>
        </v-card>
        <v-snackbar v-model="snackbar" :timeout="timeout" color="red accent-2">
          {{ text }}
          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "loginView",
  data() {
    return {
      username: "",
      password: "",
      incorrectAuth: false,
      snackbar: false,
      textResponse:
        "No se encontró una cuenta de usuario activa para las credenciales dadas",
      timeout: 10000,
    };
  },
  computed: {
    text: function () {
      return this.textResponse;
    },
  },
  mounted() {},
  methods: {
    ...mapActions("auth", ["authLogin"]),
    async login() {
      const res = await this.authLogin({
        username: this.username,
        password: this.password,
      });

      // eslint-disable-next-line no-unused-vars
      const { status, textResponse } = res;
      console.log("textResponse :>> ", textResponse);
      this.textResponse = textResponse;
      this.snackbar = !status;
    },
  },
};
</script>

<style>
body {
  background-color: #f4f4f4;
}
.login {
  background-color: #fff;
  margin-top: 10%;
}
input {
  padding: 25px 10px;
}
</style>
