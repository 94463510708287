import Vue from "vue";
import Vuex from "vuex";
import { ordersForm } from "./Orders/OrdersForms";
import { listOrders } from "./Orders/List";
import { auth } from "./Login/authLogin";

Vue.use(Vuex);

const Store = new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    ordersForm,
    listOrders,
  },
});

export default Store;
