import { getOrders } from "@/services";

export const listOrders = {
  namespaced: true,
  state: {
    orders: [],
    totalOrders: 0,
  },
  mutations: {
    setOrders(state, payload) {
      state.orders = payload;
    },
    setTotalOrders(state, payload) {
      state.totalOrders = payload;
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async getOrdersAction({ commit }, payload) {
      const { page, itemsPerPage } = payload;
      const res = await getOrders(page, itemsPerPage);
      const { count, results } = res.data;
      commit("setOrders", results);
      commit("setTotalOrders", count);
    },
  },
  getters: {},
};
