import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login/LoginView.vue";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: { type: "usuario", icon: "mdi-home" },
  },
  {
    path: "/usuario/form-kit",
    name: "Pedido de Kits",
    meta: { type: "usuario", icon: "mdi-form-select", auth: true },

    component: () =>
      import(
        /* webpackChunkName: "SteppersForm" */ "../views/Usuario/SteppersOrders.vue"
      ),
  },
  {
    path: "/usuario/pedidos",
    name: "Lista de pedidos",
    meta: { type: "usuario", icon: "mdi-history", auth: true },

    component: () =>
      import(
        /* webpackChunkName: "ListadoPedidos" */ "../views/Usuario/ListOrders.vue"
      ),
  },
  {
    path: "/login",
    name: "login",
    meta: { type: "login", icon: "mdi-form-select" },
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("accessToken");
  if (to.name !== "login" && !isAuthenticated) next({ name: "login" });
  else next();
});

export default router;
