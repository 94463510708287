<template>
  <v-card elevation="12" width="256" class="">
    <v-navigation-drawer floating permanent>
      <v-list dense rounded>
        <v-list-item-title> Secciones </v-list-item-title>
        <v-divider class="my-3" />
        <v-list-item
          v-for="item in routes"
          :key="item.name"
          link
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon>{{ item.meta.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      user: {
        type: "usuario",
      },
    };
  },
  computed: {
    routes() {
      return [
        ...this.$router.options.routes.filter(
          (val) => val.meta?.type === this.user.type
        ),
      ];
    },
  },
};
</script>
