import Axios from "axios";

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

console.log("API_URL :>> ", process.env.VUE_APP_API_URL);

const instance = Axios.create();

const ROOT = "api/v1.0/";

const routes = {
  api_token: "api/token/",
  api_refresh: "api/token/refresh/",
  api_users: `${ROOT}users/`,
  orders: `${ROOT}orders/`,
};

export const verifyToken = (accessToken) => {
  return instance.get(VUE_APP_API_URL + routes.api_users, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

export const refreshToken = (refreshToken) => {
  return instance.post(VUE_APP_API_URL + routes.api_refresh, {
    refresh: refreshToken,
  });
};

export const postLogin = ({ username, password }) => {
  return instance.post(VUE_APP_API_URL + routes.api_token, {
    username,
    password,
  });
};

export const postOrder = (payload) => {
  const accessToken = localStorage.getItem("accessToken");
  return instance.post(VUE_APP_API_URL + routes.orders, payload, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

export const getOrders = (page, itemsPerPage) => {
  const accessToken = localStorage.getItem("accessToken");
  console.log("data :>> ", page, itemsPerPage);
  return instance.get(
    VUE_APP_API_URL + routes.orders + `?size=${itemsPerPage}&page=${page}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
};

export const getUserAdmin = () => {
  const accessToken = localStorage.getItem("accessToken");
  return instance.get(VUE_APP_API_URL + routes.api_users, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};
